import { useMutation } from '@tanstack/react-query'
import { postForm } from '@ssy-blessing/apis'
import moment from 'moment'

export type FormData = {
  blessingStarLampLocation: 'ssy2' | 'home'
  genderTitle: 'mr' | 'miss' | 'mrs' | 'n/a'
  lastName: string | undefined
  firstName: string | undefined
  birthday: Date | undefined
  lunarBirthday: string | undefined
  phoneNumber: string | undefined
  email: string | undefined
}

export function useForm(formData: FormData) {
  return useMutation(['form'], () =>
    postForm({
      title: formData.genderTitle,
      firstName: formData.firstName ?? '',
      lastName: formData.lastName ?? '',
      birthday: moment(formData.birthday).toISOString(),
      lunarBirthday: formData.lunarBirthday ?? '',
      phoneNumber: formData.phoneNumber ?? '',
      emailAddress: formData.email ?? '',
      lampLocationCode: formData.blessingStarLampLocation,
    }),
  )
}
