import { memo, PropsWithChildren } from 'react'
import { ThemeProvider } from '@ssy-blessing/theme'
import { StyledEngineProvider } from '@mui/material'
import { QueryClientProvider } from '@tanstack/react-query'
import { Content } from './content'
import { theme } from './theme'
import { FullscreenLoading } from './components'
import { useInit } from './init'

const InitContainer = memo<PropsWithChildren<{}>>(({ children }) => {
  const initResult = useInit()

  if (initResult == null) return <FullscreenLoading />

  return (
    <QueryClientProvider client={initResult.queryClient}>
      {children}
    </QueryClientProvider>
  )
})

export const App = memo(() => {
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <InitContainer>
          <Content />
        </InitContainer>
      </ThemeProvider>
    </StyledEngineProvider>
  )
})
