import { memo } from 'react'
import { CircularProgress } from '@mui/material'
import styled from 'styled-components'

const SpinnerContainer = styled.div`
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const FullscreenLoading = memo(() => (
  <SpinnerContainer>
    <CircularProgress />
  </SpinnerContainer>
))
