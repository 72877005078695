import { memo, PropsWithChildren, useMemo } from 'react'
import { ThemeProvider as StyledComponentThemeProvider } from 'styled-components'
import * as fontSizes from './font-sizes'
import { icons } from './icons'
import * as palettes from './palettes'
import * as spacings from './spacings'
import { Theme, ThemeConfig } from './theme.types'

type Props = PropsWithChildren<{
  theme: ThemeConfig
}>

export interface AppTheme {}

declare module 'styled-components' {
  interface DefaultTheme extends AppTheme, Theme {}
}

export const ThemeProvider = memo<Props>(({ theme, children }) => {
  const styledComponentTheme = useMemo(
    (): Theme => ({
      colors: {
        primary: theme.colors.primary,
        accent: theme.colors.accent,
        secondary: theme.colors.secondary,
        tertiary: theme.colors.tertiary,
      },
      spacings: {
        ...spacings,
        general: theme?.spacings?.general ?? spacings.general,
      },
      palettes,
      fontSizes,
      icons: { ...icons, ...theme?.icons },
    }),
    [theme],
  )

  return (
    <StyledComponentThemeProvider theme={styledComponentTheme}>
      {children}
    </StyledComponentThemeProvider>
  )
})
