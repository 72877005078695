import { createRef, memo, useCallback, useMemo, useEffect } from 'react'
import styled from 'styled-components'
import {
  getSexagenaryCycle,
  LunarSolarConverter,
  Solar,
  monthMapping,
  dateMapping,
} from '@ssy-blessing/utilities'
import { CircularProgress } from '@mui/material'
import { useForm, FormData } from '@ssy-blessing/queries'
import { useTranslation } from '../../i18n'

const PopupRoot = styled.div<{ $isVisible: boolean }>`
  display: ${({ $isVisible }) => ($isVisible ? 'block' : 'none')} !important;
`

const ContentRoot = styled.div`
  max-height: 90vh;
  overflow-y: auto;
`

const EditButton = styled.button`
  background-color: ${({ theme }) => theme.palettes.form.edit} !important;
`

const HiddenButton = styled.button`
  display: none;
`

const SpinnerContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

const Spinner = styled(CircularProgress)`
  color: ${({ theme }) => theme.palettes.general.white};
`

type Props = {
  form: FormData
  isPopupVisible: boolean
  setPopupVisible: (val: boolean) => void
}

export const BlessingPaper = memo<Props>(
  ({ form, isPopupVisible, setPopupVisible }) => {
    const { t } = useTranslation()

    const buttonRef = createRef<HTMLButtonElement>()
    const inputRef = createRef<HTMLInputElement>()
    const formRef = createRef<HTMLFormElement>()

    const lunarBirthday = useMemo(() => {
      if (form.birthday == null) {
        return ''
      }

      const birthdayMonth = form.birthday.getMonth() + 1

      const converter = new LunarSolarConverter()
      const solar = new Solar()
      solar.solarYear = form.birthday.getFullYear()
      solar.solarMonth = birthdayMonth
      solar.solarDay = form.birthday.getDate()

      const lunar = converter.SolarToLunar(solar)
      const lunarMonth = monthMapping[lunar.lunarMonth - 1]
      const lunarDate = dateMapping[lunar.lunarDay - 1]

      return `${getSexagenaryCycle(form.birthday)}年${lunarMonth}${lunarDate}`
    }, [form.birthday])

    const {
      data: payload,
      isLoading,
      mutate: postForm,
    } = useForm({ ...form, lunarBirthday: lunarBirthday })

    const confirm = useCallback(() => {
      postForm()
    }, [postForm])

    useEffect(() => {
      if (
        payload != null &&
        buttonRef.current != null &&
        inputRef.current?.value === payload.paymentDigitalOrder &&
        formRef.current?.action === payload.paymentUrl
      ) {
        buttonRef.current.click()
      }
    }, [buttonRef, formRef, inputRef, payload])

    return (
      <PopupRoot $isVisible={isPopupVisible} id="taoist-priest-modal">
        <div
          className="taoist-priest-mask"
          onClick={() => setPopupVisible(false)}
        />
        <ContentRoot className="taoist-priest-content">
          <div className="close" onClick={() => setPopupVisible(false)}>
            {t('blessingPaper.back')}
          </div>
          <div className="body">
            <div className="script-section">
              <div className="name">
                <div className="title">
                  {t('blessingPaper.charitablePerson')}
                </div>
                <div className="content">{`${form.lastName}${form.firstName}`}</div>
              </div>
              <div className="birthday">
                <div className="title">{t('blessingPaper.birthday')}</div>
                <div className="content" id="lunar_dob">
                  {lunarBirthday}
                </div>
              </div>
              <div className="script">
                <div className="title">{t('blessingPaper.blessing.title')}</div>
                <div className="content">
                  {t('blessingPaper.blessing.first')}
                  <br />
                  {t('blessingPaper.blessing.second')}
                  <br />
                  {t('blessingPaper.blessing.third')}
                </div>
              </div>
            </div>
            <div className="contact-section">
              <div className="title">{t('blessingPaper.contact.title')}</div>
              <div className="content">
                <div className="info-row mobile">
                  <div className="label">
                    {t('blessingPaper.contact.phoneNumber')}
                  </div>
                  <div className="text">{form.phoneNumber}</div>
                </div>
                <div className="info-row email">
                  <div className="label">
                    {t('blessingPaper.contact.email')}
                  </div>
                  <div className="text">{form.email}</div>
                </div>
              </div>
            </div>
            <div className="confirm-section">
              <div className="detail">
                {t('blessingPaper.confirmSection.title')}
              </div>
              <button
                className="btn btn-theme theme--medical btn-block"
                onClick={confirm}
                disabled={isLoading}
              >
                {isLoading ? (
                  <SpinnerContainer>
                    <Spinner size={24} />
                  </SpinnerContainer>
                ) : (
                  t('blessingPaper.confirmSection.confirm')
                )}
              </button>
              <EditButton
                className="btn btn-secondary btn-block close-btn"
                onClick={() => setPopupVisible(false)}
              >
                {t('blessingPaper.confirmSection.edit')}
              </EditButton>
              <form
                ref={formRef}
                method="POST"
                action={payload?.paymentUrl ?? undefined}
              >
                <input
                  ref={inputRef}
                  type="hidden"
                  name="DO"
                  value={payload?.paymentDigitalOrder ?? undefined}
                />
                <HiddenButton ref={buttonRef} type="submit" />
              </form>
            </div>
          </div>
        </ContentRoot>
      </PopupRoot>
    )
  },
)
