import { memo } from 'react'
import { Route, Routes } from 'react-router-dom'
import { useRoutes } from './routes-items'
import { RedirectPage } from '../pages'

export const RootRouter = memo(() => {
  const routes = useRoutes()
  return (
    <Routes>
      {routes.map((it) => {
        const Component = it.component
        return <Route key={it.key} path={it.path} element={<Component />} />
      })}
      <Route path="*" element={<RedirectPage />} />
    </Routes>
  )
})
