export const blessingPaper = {
  charitablePerson: '善信',
  birthday: '生辰',
  blessing: {
    title: '一誠上達．上表祈福',
    first: '福星高照',
    second: '元辰光彩',
    third: '平安吉祥',
  },
  contact: {
    title: '聯絡資料備註',

    phoneNumber: '手提電話號碼',
    email: '電郵',
  },
  confirmSection: {
    title: '本園道長將於日後禮懺科儀上向仙師上稟閣下之祈願。',
    confirm: '確認內容 及 付款',
    edit: '修改資料',
  },
  back: 'X 返回',
}
