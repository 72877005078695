import { memo, useCallback, useState } from 'react'
import styled from 'styled-components'
import { isEmpty } from 'lodash'
import { FormData } from '@ssy-blessing/queries'
import { useTranslation } from '../../i18n'
import { FormTextInput } from '../form-text-input'
import { FormDateInput } from '../form-date-input'
import { BlessingPaper } from '../blessing-paper'

const Root = styled.div``

const initialFormValue: FormData = {
  blessingStarLampLocation: 'ssy2',
  genderTitle: 'mr',
  lastName: undefined,
  firstName: undefined,
  birthday: undefined,
  phoneNumber: undefined,
  email: undefined,
  lunarBirthday: undefined,
}

type Props = {
  isPopupVisible: boolean
  setPopupVisible: (value: boolean) => void
}

export const BlessingForm = memo<Props>(
  ({ isPopupVisible, setPopupVisible }) => {
    const { t } = useTranslation()

    const [form, setForm] = useState<FormData>(initialFormValue)
    const [isValidating, setIsValidating] = useState(false)

    const getValidationResult = useCallback(
      (
        value: string | Date | undefined,
        fieldType:
          | 'lastName'
          | 'firstName'
          | 'birthday'
          | 'phoneNumber'
          | 'email',
      ) => {
        if (fieldType === 'birthday' && value != null) {
          return undefined
        }

        if (isEmpty(value)) {
          return t('form.emptyFieldMessage', {
            fieldName: t(`form.${fieldType}`),
          })
        }

        const valString = value?.toString() ?? ''

        if (
          (fieldType === 'phoneNumber' &&
            (valString.length < 8 || valString.match(/^[0-9]+$/) == null)) ||
          (fieldType === 'email' &&
            valString.match(/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/) ==
              null)
        ) {
          return t('form.incorrectFieldMessage', {
            fieldName: t(`form.${fieldType}`),
          })
        }

        return undefined
      },
      [t],
    )

    const showPopup = useCallback(() => {
      if (
        getValidationResult(form.lastName, 'lastName') ||
        getValidationResult(form.firstName, 'firstName') ||
        getValidationResult(form.birthday, 'birthday') ||
        getValidationResult(form.phoneNumber, 'phoneNumber') ||
        getValidationResult(form.email, 'email')
      ) {
        setIsValidating(true)
        return
      }

      setIsValidating(false)
      setPopupVisible(true)
    }, [
      form.birthday,
      form.email,
      form.firstName,
      form.lastName,
      form.phoneNumber,
      getValidationResult,
      setPopupVisible,
    ])

    const reset = useCallback(() => {
      setForm(initialFormValue)
      setIsValidating(false)
    }, [])

    return (
      <Root>
        <BlessingPaper
          form={form}
          isPopupVisible={isPopupVisible}
          setPopupVisible={setPopupVisible}
        />
        <div className="container py-2 py-md-3 py-lg-5" id="taoist-priest">
          <div className="row">
            <div className="offset-xl-2 col-xl-8 offset-lg-1 col-lg-10 col-12 offset-0">
              <div className="card theme--medical">
                <div className="p-3 p-sm-3 p-md-4 p-lg-5">
                  <h4 className="card-title mb-3">{t('form.title')}</h4>
                  <div className="card-text">
                    <p>{t('form.content')}</p>
                  </div>
                  <hr />
                  <div className="editor">
                    <div className="title mb-3">{t('form.caution.title')}</div>
                    <div className="card-text">
                      <p>
                        {t('form.caution.email')}
                        <br />
                        {t('form.caution.certificate')}
                        <br />
                        {t('form.caution.phoneNumber')}
                      </p>
                    </div>
                  </div>
                  <hr />
                  <div className="row">
                    <div className="col-12 col-lg-8">
                      <div id="taoist-priest-form">
                        <div className="fields">
                          <div className="editor mb-3">
                            <div className="title mb-3">
                              {t('form.blessingStarLampLocation.title')}
                            </div>
                          </div>
                          <div className="radio-content recipe-field">
                            <div className="radio-group">
                              <input
                                checked={
                                  form.blessingStarLampLocation === 'ssy2'
                                }
                                id="wts"
                                name="place"
                                type="radio"
                                value="ssy"
                                onClick={() =>
                                  setForm({
                                    ...form,
                                    blessingStarLampLocation: 'ssy2',
                                  })
                                }
                              />
                              <label htmlFor="wts">
                                {t('form.blessingStarLampLocation.ssy')}
                              </label>
                            </div>
                            <div className="radio-group">
                              <input
                                checked={
                                  form.blessingStarLampLocation === 'home'
                                }
                                id="home"
                                name="place"
                                type="radio"
                                value="home"
                                onClick={() =>
                                  setForm({
                                    ...form,
                                    blessingStarLampLocation: 'home',
                                  })
                                }
                              />
                              <label htmlFor="home">
                                {t('form.blessingStarLampLocation.home')}
                              </label>
                            </div>
                          </div>
                        </div>
                        <hr />
                        <div className="fields">
                          <div className="editor mb-3">
                            <div className="title mb-1">
                              {t('form.personalData.title')}
                            </div>
                            <div className="description">
                              {t('form.personalData.remark')}
                            </div>
                          </div>
                          <div className="radio-content title-field">
                            <div className="radio-group">
                              <input
                                checked={form.genderTitle === 'mr'}
                                id="mr"
                                name="title"
                                type="radio"
                                value="mr"
                                onClick={() =>
                                  setForm({
                                    ...form,
                                    genderTitle: 'mr',
                                  })
                                }
                              />
                              <label htmlFor="mr">
                                {t('form.genderTitle.mr')}
                              </label>
                            </div>
                            <div className="radio-group">
                              <input
                                checked={form.genderTitle === 'miss'}
                                id="miss"
                                name="title"
                                type="radio"
                                value="miss"
                                onClick={() =>
                                  setForm({
                                    ...form,
                                    genderTitle: 'miss',
                                  })
                                }
                              />
                              <label htmlFor="miss">
                                {t('form.genderTitle.miss')}
                              </label>
                            </div>
                            <div className="radio-group">
                              <input
                                checked={form.genderTitle === 'mrs'}
                                id="mrs"
                                name="title"
                                type="radio"
                                value="mrs"
                                onClick={() =>
                                  setForm({
                                    ...form,
                                    genderTitle: 'mrs',
                                  })
                                }
                              />
                              <label htmlFor="mrs">
                                {t('form.genderTitle.mrs')}
                              </label>
                            </div>
                            <div className="radio-group">
                              <input
                                checked={form.genderTitle === 'n/a'}
                                id="not_applicable"
                                name="title"
                                type="radio"
                                value="not_applicable"
                                onClick={() =>
                                  setForm({
                                    ...form,
                                    genderTitle: 'n/a',
                                  })
                                }
                              />
                              <label htmlFor="not_applicable">
                                {t('form.genderTitle.notApplicable')}
                              </label>
                            </div>
                          </div>
                        </div>
                        <div className="fields">
                          <div className="d-flex name-group">
                            <FormTextInput
                              className="form-control"
                              id="lastname"
                              maxLength={20}
                              name="lastname"
                              placeholder={`${t('form.lastName')}*`}
                              required
                              type="text"
                              value={form.lastName}
                              onChange={(event) =>
                                setForm({
                                  ...form,
                                  lastName: event.target.value,
                                })
                              }
                              validationMessage={
                                isValidating
                                  ? getValidationResult(
                                      form.lastName,
                                      'lastName',
                                    )
                                  : undefined
                              }
                              isNameField
                            />
                            <FormTextInput
                              className="form-control"
                              id="firstname"
                              maxLength={20}
                              name="firstname"
                              placeholder={`${t('form.firstName')}*`}
                              required
                              type="text"
                              value={form.firstName}
                              onChange={(event) =>
                                setForm({
                                  ...form,
                                  firstName: event.target.value,
                                })
                              }
                              validationMessage={
                                isValidating
                                  ? getValidationResult(
                                      form.firstName,
                                      'firstName',
                                    )
                                  : undefined
                              }
                              isNameField
                            />
                          </div>
                          <FormDateInput
                            id="date"
                            className="form-control datepicker flatpickr-input"
                            value={form.birthday}
                            onChange={([date]) => {
                              setForm({
                                ...form,
                                birthday: date,
                              })
                            }}
                            placeholder={`${t('form.birthday')}*`}
                            required
                            validationMessage={
                              isValidating
                                ? getValidationResult(form.birthday, 'birthday')
                                : undefined
                            }
                            options={{
                              minDate: '1900-01-01',
                              maxDate: 'today',
                            }}
                          />
                          <FormTextInput
                            className="form-control"
                            id="tel"
                            maxLength={20}
                            name="tel"
                            placeholder={`${t('form.phoneNumber')}*`}
                            required
                            type="tel"
                            value={form.phoneNumber}
                            onChange={(event) =>
                              setForm({
                                ...form,
                                phoneNumber: event.target.value,
                              })
                            }
                            validationMessage={
                              isValidating
                                ? getValidationResult(
                                    form.phoneNumber,
                                    'phoneNumber',
                                  )
                                : undefined
                            }
                          />
                          <FormTextInput
                            className="form-control"
                            id="email"
                            maxLength={50}
                            name="email"
                            placeholder={`${t('form.email')}*`}
                            required
                            type="email"
                            value={form.email}
                            onChange={(event) =>
                              setForm({
                                ...form,
                                email: event.target.value,
                              })
                            }
                            validationMessage={
                              isValidating
                                ? getValidationResult(form.email, 'email')
                                : undefined
                            }
                          />
                        </div>
                        <div className="form-group">
                          <button
                            className="btn-theme btn btn-block theme--medical"
                            onClick={showPopup}
                          >
                            {t('form.submit')}
                          </button>
                          <button
                            type="reset"
                            className="btn-secondary btn btn-block"
                            onClick={reset}
                          >
                            {t('form.reset')}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Root>
    )
  },
)
