import { memo } from 'react'
import { BrowserRouter as Router } from 'react-router-dom'
import { RootRouter } from './routers'
import { useInit } from './init'

export const Content = memo(() => {
  const initResult = useInit()

  if (initResult == null) {
    return null
  }

  return (
    <Router>
      <RootRouter />
    </Router>
  )
})
