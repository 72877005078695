import { usePaymentResult } from '@ssy-blessing/queries'
import { memo, useCallback, useEffect, useRef } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import ReactToPrint from 'react-to-print'
import { env } from '../utilities'
import { FullscreenLoading } from '../components'
import { useTranslation } from '../i18n'

const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const TitleContainer = styled.div`
  padding-top: ${({ theme }) => theme.spacings.general[3]}px;
`

const OrderList = styled.ol`
  padding-left: ${({ theme }) => theme.spacings.general[3]}px;
`

const ListItem = styled.li`
  list-style: circle;
  margin-bottom: ${({ theme }) => theme.spacings.general[3]}px;
`

const SubitemContainer = styled.ol`
  margin-top: ${({ theme }) => theme.spacings.general[3]}px;
`

const ImageContainer = styled.div`
  width: 120px;
  border: 0px;
  display: inline-block;
`

const PrintButton = styled.input`
  margin-bottom: ${({ theme }) => theme.spacings.general[2]}px;
`

const BackButton = styled.input`
  color: #707070;
`

const SubItemOrderList = styled.ol`
  padding-left: ${({ theme }) => theme.spacings.general[2]}px;
`

type PaymentResultPageProps = {
  referenceID: string
}

export const PaymentResultPage = memo(() => {
  const { referenceID } = useParams<PaymentResultPageProps>()
  const { t } = useTranslation()
  const navigate = useNavigate()
  const componentRef = useRef<HTMLDivElement>(null)

  const { data, isFetched } = usePaymentResult(referenceID)

  useEffect(() => {
    if (isFetched && data == null) {
      navigate(env.blessingPagePath)
    }
  }, [data, isFetched, navigate])

  const handleBackButtonOnClick = useCallback(() => {
    navigate(env.blessingPagePath)
  }, [navigate])

  if (data == null) {
    return <FullscreenLoading />
  }

  if (data.status === 'paid')
    return (
      <div
        className="container py-2 py-md-3 py-lg-5"
        id="resultdiv"
        ref={componentRef}
      >
        <div className="row">
          <div className="offset-xl-2 col-xl-8 offset-lg-1 col-lg-10 col-12 offset-0">
            <div className="card theme--medical">
              <div className="p-3 p-sm-3 p-md-4 p-lg-5">
                <HeaderContainer>
                  <ImageContainer>
                    <img src="/image/logo.png" width="100%" />
                  </ImageContainer>
                  <div className="mb-3 text-right">
                    {t('resultPage.reference', {
                      referenceNumber: data.paymentInvoiceNumber,
                    })}
                  </div>
                </HeaderContainer>

                <TitleContainer className="mb-3 d-flex justify-content-center">
                  {t('resultPage.title')}
                </TitleContainer>
                <div className="card-text">
                  <div className="mb-3 d-flex justify-content-center">
                    {t('resultPage.header')}
                  </div>
                  <div className=" mb-3  d-flex justify-content-center">
                    <u>{t('resultPage.certificate')}</u>
                  </div>

                  <div>
                    <OrderList>
                      <ListItem>{t('resultPage.successMessage')}</ListItem>
                      <ListItem>{t('resultPage.reason')}</ListItem>
                      <ListItem>{t('resultPage.disclaimer')}</ListItem>
                      <ListItem>{t('resultPage.leftInSSY')}</ListItem>
                      <ListItem>
                        {t('resultPage.detail.title')}
                        <SubItemOrderList>
                          <SubitemContainer>
                            <li>{t('resultPage.detail.time')}</li>
                            <li>{t('resultPage.detail.location')}</li>
                          </SubitemContainer>
                        </SubItemOrderList>
                      </ListItem>
                      <ListItem>
                        {t('resultPage.reminder.notOfficialReceipt')}
                      </ListItem>
                      <ListItem>
                        {t('resultPage.reminder.phoneNumber')}
                      </ListItem>
                      <ListItem>
                        {t('resultPage.reminder.finalDecision')}
                      </ListItem>
                      <li className="text-right">{t('resultPage.ssy')}</li>
                    </OrderList>
                  </div>

                  <div>
                    <ReactToPrint
                      trigger={() => (
                        <PrintButton
                          className="btn btn-success d-print-none btn-block"
                          value={t('resultPage.print') ?? '列印'}
                          id="printbtn"
                          type="button"
                        />
                      )}
                      content={() => componentRef.current}
                    />
                  </div>
                  <BackButton
                    className="btn-secondary btn btn-block btn-success d-print-none"
                    value={t('resultPage.back') ?? '返回'}
                    id="leaveBtn"
                    type="button"
                    onClick={handleBackButtonOnClick}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )

  return (
    <div className="container py-2 py-md-3 py-lg-5" id="resultdiv">
      <div className="row">
        <div className="offset-xl-2 col-xl-8 offset-lg-1 col-lg-10 col-12 offset-0">
          <div className="card theme--medical">
            <div className="p-3 p-sm-3 p-md-4 p-lg-5">
              <ImageContainer>
                <img src="/image/logo.png" width="100%" />
              </ImageContainer>
              <TitleContainer className="mb-3 d-flex justify-content-center">
                {t('resultPage.title')}
              </TitleContainer>
              <div className="card-text">
                <div className="mb-3 d-flex justify-content-center">
                  {t('resultPage.header')}
                </div>
                <div className=" mb-3  d-flex justify-content-center">
                  {t('resultPage.failedMessage')}
                </div>
                <div className="mb-3 d-flex justify-content-center">
                  {t('resultPage.failedReference', {
                    referenceNumber: data.paymentInvoiceNumber,
                  })}
                </div>
                <BackButton
                  className="btn-secondary btn btn-block d-print-none"
                  value={t('resultPage.back') ?? '返回'}
                  id="leaveBtn"
                  type="button"
                  onClick={handleBackButtonOnClick}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
})
