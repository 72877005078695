import { common } from './common'
import { form } from './form'
import { blessingPaper } from './blessing-paper'
import { resultPage } from './result-page'

export const zh = {
  common,
  form,
  blessingPaper,
  resultPage,
}
