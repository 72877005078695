import { memo } from 'react'
import styled from 'styled-components'

const BannerImage = styled.div`
  background-image: url(/image/banner-blessing.jpeg);
`

const TitleMask = styled.div`
  background-image: url(/image/banner-title-mask.png);
`

export const Banner = memo(() => (
  <div className="inner-page">
    <BannerImage className="banner">
      <div className="container">
        <div className="title-container">
          <div className="title">道長代辦上表</div>
          <TitleMask className="title-mask" />
        </div>
      </div>
    </BannerImage>
  </div>
))
