import { useMemo } from 'react'
import { BlessingPage, PaymentResultPage } from '../pages'
import { env } from '../utilities'

type RouteItem = {
  key: string
  path: string
  component: React.FunctionComponent
}

export const useRoutes = (): RouteItem[] => {
  const routeItems = useMemo(
    (): RouteItem[] => [
      {
        key: 'blessing',
        path: env.blessingPagePath,
        component: BlessingPage,
      },
      {
        key: 'payment',
        path: '/online-applications/:referenceID',
        component: PaymentResultPage,
      },
    ],
    [],
  )

  return useMemo(() => routeItems, [routeItems])
}
