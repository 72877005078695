import { useEffect, useState } from 'react'
import { Api } from '@ssy-blessing/api-core'
import { QueryClient } from '@tanstack/react-query'
import { i18nInit } from '../i18n'
import { env } from '../utilities'

export function useInit() {
  const [initResult, setInitResult] = useState<
    { queryClient: QueryClient } | undefined
  >(undefined)

  useEffect(() => {
    ;(async () => {
      i18nInit()
      Api.primary.setConfig({
        baseUrl: env.apiBaseUrl,
        timeout: +env.apiTimeout,
      })

      const queryClient = new QueryClient({
        defaultOptions: {
          queries: {
            structuralSharing: false,
            refetchOnWindowFocus: false,
            retry: false,
          },
        },
      })
      setInitResult({ queryClient })
    })()
  }, [])

  return initResult
}
