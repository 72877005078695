export const form = {
  title: '道長代辦上表',
  content:
    '有鑑太歲元辰殿成立至今，不少善信因身處海外或公務繁重而錯過在新春上表祈福，本園決定大開方便之門，由即日起安排道長代辦上表祈福拜太歲儀式，使各信眾皆得星君太歲庇佑，平安健康、順利如意。',
  caution: {
    title: '注意事項',
    email:
      '閣下必須正確填寫電郵地址，如資料有誤，將不會收到由系統自動發出的「報名證明」。',
    certificate:
      '閣下可憑「報名證明」於完成儀式的一個月後 到本園總辦事處領取上表祈福福物帶回家中。',
    phoneNumber:
      '本園將按 閣下所登記之「手提電話號碼」於年末發出「酬神提示短訊」。如登記之資料有誤， 閣下將不會收到相關訊息；如有任何查詢，歡迎聯絡本園電話：2327 8141。',
  },
  blessingStarLampLocation: {
    title: '祈福星燈擺放地點',
    ssy: '黃大仙祠第二參神平台',
    home: '帶回家中',
  },
  personalData: { title: '善信資料', remark: '* 為必須填寫的資料' },
  genderTitle: {
    mr: '先生',
    miss: '小姐',
    mrs: '太太',
    notApplicable: '不適用',
  },
  lastName: '姓氏',
  firstName: '名字',
  birthday: '陽曆出生日期',
  phoneNumber: '電話號碼',
  email: '電郵地址',
  submit: '提交',
  reset: '重置',
  emptyFieldMessage: '請輸入{{fieldName}}',
  incorrectFieldMessage: '請輸入正確的{{fieldName}}',
}
