import { memo, InputHTMLAttributes } from 'react'
import styled from 'styled-components'

const Root = styled.div<{
  $hasValidationMessage: boolean
  $isDivided: boolean
}>`
  margin-bottom: ${({ theme, $hasValidationMessage }) =>
    theme.spacings.general[$hasValidationMessage ? 1 : 4]}px;
  padding-right: 10px;
  @media only screen and (max-width: 767.98px) {
    flex: 1 1 100%;
  }
  flex: 1 1 ${({ $isDivided }) => ($isDivided ? '50%' : '100%')};
`

const Input = styled.input<{ $isInvalid: boolean }>`
  background-color: ${({ theme, $isInvalid }) =>
    $isInvalid
      ? theme.palettes.form.invalid
      : theme.palettes.form.normal}!important;
  border: 1px solid
    ${({ theme }) => theme.palettes.form.inputBorderColor}!important;
`

const ErrorMessage = styled.p`
  color: ${({ theme }) => theme.palettes.form.errorMessage};
  font-size: ${({ theme }) => theme.fontSizes.small}px;
  padding: ${({ theme }) => theme.spacings.general[1]}px;
  margin: ${({ theme }) => theme.spacings.general[0]}px;
`

type Props = {
  validationMessage: string | undefined
  isNameField?: boolean
} & InputHTMLAttributes<HTMLInputElement>

// Customized Input component with validation
export const FormTextInput = memo<Props>((props) => {
  const { validationMessage, isNameField = false, value, ...rest } = props

  return (
    <Root
      $hasValidationMessage={validationMessage != null}
      $isDivided={isNameField}
    >
      <Input
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...rest}
        value={value ?? ''}
        $isInvalid={validationMessage != null}
      />
      {validationMessage && <ErrorMessage>{validationMessage}</ErrorMessage>}
    </Root>
  )
})
