import { memo } from 'react'
import styled from 'styled-components'

const Padding = styled.div`
  padding-left: 12px;
`

export const Appbar = memo(() => (
  <div id="site-header">
    <div className="header-logo d-flex justify-content-center">
      <h1 className="site-logo">
        <a
          href="https://www.siksikyuen.org.hk/"
          target="_blank"
          rel="noreferrer"
        >
          <img
            src="/image/logo.png"
            id="webpage_logo"
            srcSet="/image/logo@2x.png 2x"
          />
        </a>
      </h1>
      <Padding />
      <a
        href="https://www.siksikyuen100.org.hk/zh-hant/"
        target="_blank"
        rel="noreferrer"
      >
        <img
          src="/image/logo_100th.png"
          id="webpage_subLogo"
          srcSet="/image/logo_100th@2x.png 2x"
        />
      </a>
    </div>
  </div>
))
