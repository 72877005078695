import {
  ApiErrorResponse,
  ApiResponse,
  ApisauceInstance,
  create,
  TIMEOUT_ERROR,
} from 'apisauce'
import Axios, { AxiosRequestConfig } from 'axios'
import { ApiError } from './api-error'
import { ApiProblem, getApiProblem } from './api-problem'

const DEFAULT_TIMEOUT = 8000

type ApiConfig = {
  baseUrl?: string
  timeout?: number
} & Omit<AxiosRequestConfig, 'baseURL' | 'timeout' | 'url'>

type ApiOptions<T> = {
  config?: AxiosRequestConfig<T>
}

export class Api {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private static _default: Api

  // eslint-disable-next-line @typescript-eslint/naming-convention
  private static _primary: Api

  /**
   * Default API singleton
   */
  public static get default() {
    if (Api._default == null) Api._default = new Api()
    return Api._default
  }

  /**
   * Primary API singleton
   */
  public static get primary() {
    if (Api._primary == null) Api._primary = new Api()
    return Api._primary
  }

  /**
   * Set config for both `Api.default` and `Api.primary` instance
   * Note that `baseUrl` will only be applied to Api.primary
   *
   * @param apiConfig.baseUrl Base url of all API calls for the `Api.primary` instance
   * @param apiConfig.timeout Timeout in ms for API calls
   * @param apiConfig         Any other AxiosRequestConfig are accepted
   */
  public static setConfig(apiConfig?: ApiConfig) {
    Api.default.setConfig({ ...apiConfig, baseUrl: undefined })
    Api.primary.setConfig(apiConfig)
  }

  public verbose = false

  private apisauce!: ApisauceInstance

  private config?: ApiConfig

  private authorizationHeader?: string

  private onUnauthorized?: {
    handler: (
      url: string | undefined,
      config: AxiosRequestConfig,
    ) => Promise<string | null>
    delayTimeout?: boolean
  }

  private unauthorizeHandlingPromise: Promise<string | null> | undefined

  /**
   * Create a new API instance.
   *
   * In most cases, you should not need to construct the API instance yourself.
   * Use the singleton object  `Api.default` or `Api.primary`
   */
  public constructor() {
    // construct the apisauce instance
    this.setConfig()
  }

  // make timeout working on Android:
  // https://github.com/infinitered/apisauce/issues/163#issuecomment-441475858
  private requestTimeout = <T, U = T>(
    promise: Promise<ApiResponse<T, U>>,
    axiosConfig: AxiosRequestConfig,
    args: [string, {}?, AxiosRequestConfig?],
  ): Promise<ApiResponse<T, U>> => {
    const config = { ...axiosConfig, ...(args[2] ?? {}) }

    // ? to handle timeout = 0
    // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
    const duration = config.timeout || this.config?.timeout || DEFAULT_TIMEOUT
    let timeout: NodeJS.Timeout | undefined

    const timeoutPromise = new Promise<ApiErrorResponse<U>>((resolve) => {
      timeout = setInterval(() => {
        if (
          this.onUnauthorized?.delayTimeout &&
          this.unauthorizeHandlingPromise != null
        ) {
          return
        }
        if (timeout != null) clearInterval(timeout)
        timeout = undefined

        resolve({
          ok: false,
          problem: TIMEOUT_ERROR,
          originalError: {
            config,
            isAxiosError: false,
          },
          data: undefined,
          status: undefined,
          headers: undefined,
          config,
          duration,
        } as ApiErrorResponse<U>)
      }, duration)
    })

    return Promise.race([timeoutPromise, promise]).then((res) => {
      if (timeout != null) clearInterval(timeout)

      /* istanbul ignore next */
      if (this.verbose && res.problem === TIMEOUT_ERROR) {
        console.warn({
          name: 'API Timeout',
          important: true,
          preview: `${args[0]} (${duration} ms)`,
          value: [res, args],
        })
      }
      return res
    })
  }

  /**
   * Set HTTP Authorization header field for subsequent request of this instance
   *
   * @param authHeader Usually the Bearer token: include the leading `Bearer` in the parameter. Set to `null` to unset the token
   * @returns The Api instance, for chaining
   */
  public setAuthorizationHeader(authHeader: string | null): Api {
    if (this === Api._default) {
      console.warn(
        'Api.default should not be altered. Ignore setAuthHeader(). ',
      )
      return this
    }

    this.authorizationHeader = authHeader ?? undefined
    return this
  }

  /**
   * Set a custom handler on API response 401 for refreshing the identity
   * The handler should return a new Authorization header value, or null to indicates unavailable of refreshed identity
   * If the handler returns an Authorization header value, failed request will be retried with the refreshed identity
   * When not Authorization header value returned, or the request still returns 401 with the refreshed identity, the original promise will thrown Error
   *
   * @param opts.handler      The handler on unauthorized request
   * @param opts.delayTimeout Option to delay general API timeout during handler in process
   * @returns The Api instance, for chaining
   */
  public setUnauthorizedHandler(
    opts:
      | {
          handler: (
            url: string | undefined,
            config: AxiosRequestConfig,
          ) => Promise<string | null>
          delayTimeout?: boolean
        }
      | undefined,
  ): Api {
    this.onUnauthorized =
      opts == null
        ? undefined
        : { handler: opts.handler, delayTimeout: opts.delayTimeout }
    return this
  }

  /**
   * Set config for the Api instance
   * Note that `baseUrl` should not be passed for `Api.default` instance
   *
   * @param apiConfig.baseUrl Base url of all API calls for the Api.primary instance
   * @param apiConfig.timeout Timeout in ms for API calls
   * @param apiConfig         Any other AxiosRequestConfig are accepted
   */
  public setConfig(apiConfig?: ApiConfig) {
    const isDefault = this === Api._default

    if (isDefault && apiConfig?.baseUrl != null) {
      console.warn(
        'Api.default base url should not be altered. Ignore base url in setConfig(). ',
      )
    }

    this.config = { ...this.config, ...apiConfig }

    const api = create({
      ...this.config,
      baseURL: !isDefault ? this.config?.baseUrl : undefined,
      timeout: this.config?.timeout,
      headers: {
        Accept: 'application/json',
        ...this.config?.headers,
      },
    })

    const {
      axiosInstance: { defaults },
      get,
      delete: del,
      head,
      post,
      put,
      patch,
      link,
      unlink,
    } = api

    api.get = (...args) => this.requestTimeout(get(...args), defaults, args)
    api.delete = (...args) => this.requestTimeout(del(...args), defaults, args)
    api.head = (...args) => this.requestTimeout(head(...args), defaults, args)
    api.post = (...args) => this.requestTimeout(post(...args), defaults, args)
    api.put = (...args) => this.requestTimeout(put(...args), defaults, args)
    api.patch = (...args) => this.requestTimeout(patch(...args), defaults, args)
    api.link = (...args) => this.requestTimeout(link(...args), defaults, args)
    api.unlink = (...args) =>
      this.requestTimeout(unlink(...args), defaults, args)

    api.axiosInstance.interceptors.response.use(
      this.responseSuccessInterceptor,
      this.responseErrorInterceptor,
    )

    this.apisauce = api

    return this
  }

  private responseErrorInterceptor = async (error: {
    response: { status: number }
    config: AxiosRequestConfig
  }): Promise<any> => {
    if (error.response == null) return Promise.reject(error)
    if (error.response.status !== 401) return Promise.reject(error)

    if (this.onUnauthorized == null) return Promise.reject(error)

    const originalRequest = error.config

    // @ts-ignore
    // reject the request if it is still failing in retry
    if (originalRequest.isRetry) return Promise.reject(error)

    let refreshedAuthorizationHeader: string | null
    try {
      if (this.unauthorizeHandlingPromise == null) {
        this.unauthorizeHandlingPromise = this.onUnauthorized.handler(
          error.config.url,
          error.config,
        )
      }

      refreshedAuthorizationHeader = await this.unauthorizeHandlingPromise
    } catch (err) {
      return await Promise.reject(error) // throwing the original error
    } finally {
      this.unauthorizeHandlingPromise = undefined
    }

    if (refreshedAuthorizationHeader == null) {
      return Promise.reject(error)
    }

    this.setAuthorizationHeader(refreshedAuthorizationHeader)

    // @ts-ignore
    originalRequest.isRetry = true
    originalRequest.headers = {
      ...originalRequest.headers,
      authorization: refreshedAuthorizationHeader,
    }

    return Axios(originalRequest)
  }

  private responseSuccessInterceptor = async (value: any) => value

  private async plainApi<T>(
    apiPromise: Promise<ApiResponse<T>>,
  ): Promise<T | null> {
    let response: ApiResponse<T> | ApiProblem | null

    try {
      response = await apiPromise
    } catch (err) {
      /* istanbul ignore next */
      if (this.verbose) console.warn('Unknown API failure', err)
      throw new ApiError<T>({ kind: 'unknown' }, undefined, err as Error)
    }

    if (response == null || !response.ok) {
      const problem = getApiProblem(response)
      if (problem != null) throw new ApiError<T>(problem, response)
    }

    return (response as ApiResponse<T>).data ?? null
  }

  private transformConfig<T = any>(
    opts?: ApiOptions<T>,
  ): AxiosRequestConfig<T> | undefined {
    if (this.authorizationHeader == null || this.authorizationHeader === '')
      return opts?.config

    return {
      ...opts?.config,
      headers: {
        ...opts?.config?.headers,
        authorization: this.authorizationHeader,
      },
    }
  }

  /**
   * Fire a GET request to an endpoint
   *
   * @param endpoint    The API endpoint. If the path is not a full URL, `apiConfig.baseUrl` will be prepended
   * @param opts.param  Query parameters of the API
   * @param opts.config Config for the API request. See AxiosRequestConfig for detail
   * @param T           Type of the API response
   * @returns           Promise of the API request. Resolve as `T`, or `null` if the response is empty, or exception thrown
   */
  public get<T = any>(
    endpoint: string,
    opts?: ApiOptions<T> & {
      param?: {}
    },
  ) {
    return this.plainApi<T>(
      this.apisauce.get(endpoint, opts?.param, this.transformConfig(opts)),
    )
  }

  /**
   * Fire a DELETE request to an endpoint
   *
   * @param endpoint    The API endpoint. If the path is not a full URL, `apiConfig.baseUrl` will be prepended
   * @param opts.param  Query parameters of the API
   * @param opts.config Config for the API request. See AxiosRequestConfig for detail
   * @param T           Type of the API response
   * @returns           Promise of the API request. Resolve as `T`, or `null` if the response is empty, or exception thrown
   */
  public delete<T = any>(
    endpoint: string,
    opts?: ApiOptions<T> & {
      param?: {}
    },
  ) {
    return this.plainApi<T>(
      this.apisauce.delete(endpoint, opts?.param, this.transformConfig(opts)),
    )
  }

  /**
   * Fire a HEAD request to an endpoint
   *
   * @param endpoint    The API endpoint. If the path is not a full URL, `apiConfig.baseUrl` will be prepended
   * @param opts.param  Query parameters of the API
   * @param opts.config Config for the API request. See AxiosRequestConfig for detail
   * @param T           Type of the API response
   * @returns           Promise of the API request. Resolve as `T`, or `null` if the response is empty, or exception thrown
   */
  public head<T = any>(
    endpoint: string,
    opts?: ApiOptions<T> & {
      param?: {}
    },
  ) {
    return this.plainApi<T>(
      this.apisauce.head(endpoint, opts?.param, this.transformConfig(opts)),
    )
  }

  /**
   * Fire a LINK request to an endpoint
   *
   * @param endpoint    The API endpoint. If the path is not a full URL, `apiConfig.baseUrl` will be prepended
   * @param opts.param  Query parameters of the API
   * @param opts.config Config for the API request. See AxiosRequestConfig for detail
   * @param T           Type of the API response
   * @returns           Promise of the API request. Resolve as `T`, or `null` if the response is empty, or exception thrown
   */
  public link<T = any>(
    endpoint: string,
    opts?: ApiOptions<T> & {
      param?: {}
    },
  ) {
    return this.plainApi<T>(
      this.apisauce.link(endpoint, opts?.param, this.transformConfig(opts)),
    )
  }

  /**
   * Fire a UNLINK request to an endpoint
   *
   * @param endpoint    The API endpoint. If the path is not a full URL, `apiConfig.baseUrl` will be prepended
   * @param opts.param  Query parameters of the API
   * @param opts.config Config for the API request. See AxiosRequestConfig for detail
   * @param T           Type of the API response
   * @returns           Promise of the API request. Resolve as `T`, or `null` if the response is empty, or exception thrown
   */
  public unlink<T = any>(
    endpoint: string,
    opts?: ApiOptions<T> & {
      param?: {}
    },
  ) {
    return this.plainApi<T>(
      this.apisauce.unlink(endpoint, opts?.param, this.transformConfig(opts)),
    )
  }

  /**
   * Fire a POST request to an endpoint
   *
   * @param endpoint    The API endpoint. If the path is not a full URL, `apiConfig.baseUrl` will be prepended
   * @param opts.body   Request body of the API
   * @param opts.config Config for the API request. See AxiosRequestConfig for detail
   * @param T           Type of the API response
   * @returns           Promise of the API request. Resolve as `T`, or `null` if the response is empty, or exception thrown
   */
  public post<T = any>(
    endpoint: string,
    opts?: ApiOptions<T> &
      (
        | {
            type?: 'application/json'
            body?: any
          }
        | { type: 'application/x-www-form-urlencoded'; body: string }
      ),
    // | { type: 'multipart/form-data'; body: FormData }
  ) {
    return this.plainApi<T>(
      this.apisauce.post(
        endpoint,
        opts?.body,
        this.transformConfig({
          ...opts,
          config: {
            ...opts?.config,
            headers: {
              ...opts?.config?.headers,
              'content-type':
                opts?.type ??
                opts?.config?.headers?.['content-type'] ??
                'application/json',
            },
          },
        }),
      ),
    )
  }

  /**
   * Fire a PUT request to an endpoint
   *
   * @param endpoint    The API endpoint. If the path is not a full URL, `apiConfig.baseUrl` will be prepended
   * @param opts.body   Request body of the API
   * @param opts.config Config for the API request. See AxiosRequestConfig for detail
   * @param T           Type of the API response
   * @returns           Promise of the API request. Resolve as `T`, or `null` if the response is empty, or exception thrown
   */
  public put<T = any>(
    endpoint: string,
    opts?: ApiOptions<T> & {
      body?: any
    },
  ) {
    return this.plainApi<T>(
      this.apisauce.put(endpoint, opts?.body, this.transformConfig(opts)),
    )
  }

  /**
   * Fire a PATCH request to an endpoint
   *
   * @param endpoint    The API endpoint. If the path is not a full URL, `apiConfig.baseUrl` will be prepended
   * @param opts.body   Request body of the API
   * @param opts.config Config for the API request. See AxiosRequestConfig for detail
   * @param T           Type of the API response
   * @returns           Promise of the API request. Resolve as `T`, or `null` if the response is empty, or exception thrown
   */
  public patch<T = any>(
    endpoint: string,
    opts?: ApiOptions<T> & {
      body?: any
    },
  ) {
    return this.plainApi<T>(
      this.apisauce.patch(endpoint, opts?.body, this.transformConfig(opts)),
    )
  }
}
