export const heading1 = 35
export const heading2 = 30
export const heading3 = 24
export const heading4 = 22
export const heading5 = 20

export const large = 18
export const medium = 16
export const normal = 14
export const small = 12
