export const resultPage = {
  title: '嗇色園太歲元辰殿',
  header: '道長代辦 上表祈福拜太歲儀式',
  certificate: '報名證明',
  reference: '參考編號：{{referenceNumber}}',
  successMessage:
    '閣下已成功報名「道長代辦上表祈福拜太歲儀式」，本園將於閣下完成報名後之一星期內安排道長進行上表祈福拜太歲儀式。',
  failedMessage: '報名失敗',
  reason:
    '網上代辦上表緣起於方便善信因身處海外或公務繁重未能親臨本園，可以網上登記上表祈福，儀式由道長親為眾善信稟告斗姥元君，使各信眾皆得星君太歲庇佑。',
  emailNotification:
    '「道長代辦上表祈福拜太歲儀式」完成後本園將會以電郵形式通知。',
  disclaimer:
    '如遇上特殊及不可控制情況，代辦上表祈福之時間安排或會有所調整或延遲，恕不作另行通知。',
  leftInSSY:
    '如 閣下在報名表格中選擇將上表之福物「祈福星燈」懸掛於本園第二參神平台，本園會於儀式完成後代為懸掛。',
  detail: {
    title:
      '如 閣下在報名表格中選擇將上表之福物「祈福星燈」帶回家中懸掛，請在收到電郵後一個月內於下時間憑此證到本園總辦事處領取上表「祈福星燈」：',
    time: '時間：早上9:00至下午5:00',
    location: '地點：嗇色園總辦事處',
  },
  reminder: {
    notOfficialReceipt:
      '此將作為閣下報名「道長代辦上表祈福拜太歲儀式」之證明，並非正式收據。',
    phoneNumber: '如有任何查詢，歡迎聯絡本園電話：2327 8141。',
    finalDecision: '如有任何爭議，嗇色園將保留最終決定權。',
  },
  failedReference: '抱歉，閣下的報名失敗，參考編號：{{referenceNumber}}',
  ssy: '嗇色園謹啟',
  print: '列印',
  back: '返回',
}
