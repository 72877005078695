/* eslint-disable @typescript-eslint/naming-convention */

const SpringCommencesDay = {
  '1900': '1900-02-04',
  '1901': '1901-02-04',
  '1902': '1902-02-05',
  '1903': '1903-02-05',
  '1904': '1904-02-05',
  '1905': '1905-02-04',
  '1906': '1906-02-05',
  '1907': '1907-02-05',
  '1908': '1908-02-05',
  '1909': '1909-02-04',
  '1910': '1910-02-05',
  '1911': '1911-02-05',
  '1912': '1912-02-05',
  '1913': '1913-02-04',
  '1914': '1914-02-04',
  '1915': '1915-02-05',
  '1916': '1916-02-05',
  '1917': '1917-02-04',
  '1918': '1918-02-04',
  '1919': '1919-02-05',
  '1920': '1920-02-05',
  '1921': '1921-02-04',
  '1922': '1922-02-04',
  '1923': '1923-02-05',
  '1924': '1924-02-05',
  '1925': '1925-02-04',
  '1926': '1926-02-04',
  '1927': '1927-02-05',
  '1928': '1928-02-05',
  '1929': '1929-02-04',
  '1930': '1930-02-04',
  '1931': '1931-02-05',
  '1932': '1932-02-05',
  '1933': '1933-02-04',
  '1934': '1934-02-04',
  '1935': '1935-02-05',
  '1936': '1936-02-05',
  '1937': '1937-02-04',
  '1938': '1938-02-04',
  '1939': '1939-02-05',
  '1940': '1940-02-05',
  '1941': '1941-02-04',
  '1942': '1942-02-04',
  '1943': '1943-02-05',
  '1944': '1944-02-05',
  '1945': '1945-02-04',
  '1946': '1946-02-04',
  '1947': '1947-02-04',
  '1948': '1948-02-05',
  '1949': '1949-02-04',
  '1950': '1950-02-04',
  '1951': '1951-02-04',
  '1952': '1952-02-05',
  '1953': '1953-02-04',
  '1954': '1954-02-04',
  '1955': '1955-02-04',
  '1956': '1956-02-05',
  '1957': '1957-02-04',
  '1958': '1958-02-04',
  '1959': '1959-02-04',
  '1960': '1960-02-05',
  '1961': '1961-02-04',
  '1962': '1962-02-04',
  '1963': '1963-02-04',
  '1964': '1964-02-05',
  '1965': '1965-02-04',
  '1966': '1966-02-04',
  '1967': '1967-02-04',
  '1968': '1968-02-05',
  '1969': '1969-02-04',
  '1970': '1970-02-04',
  '1971': '1971-02-04',
  '1972': '1972-02-05',
  '1973': '1973-02-04',
  '1974': '1974-02-04',
  '1975': '1975-02-04',
  '1976': '1976-02-05',
  '1977': '1977-02-04',
  '1978': '1978-02-04',
  '1979': '1979-02-04',
  '1980': '1980-02-05',
  '1981': '1981-02-04',
  '1982': '1982-02-04',
  '1983': '1983-02-04',
  '1984': '1984-02-04',
  '1985': '1985-02-04',
  '1986': '1986-02-04',
  '1987': '1987-02-04',
  '1988': '1988-02-04',
  '1989': '1989-02-04',
  '1990': '1990-02-04',
  '1991': '1991-02-04',
  '1992': '1992-02-04',
  '1993': '1993-02-04',
  '1994': '1994-02-04',
  '1995': '1995-02-04',
  '1996': '1996-02-04',
  '1997': '1997-02-04',
  '1998': '1998-02-04',
  '1999': '1999-02-04',
  '2000': '2000-02-04',
  '2001': '2001-02-04',
  '2002': '2002-02-04',
  '2003': '2003-02-04',
  '2004': '2004-02-04',
  '2005': '2005-02-04',
  '2006': '2006-02-04',
  '2007': '2007-02-04',
  '2008': '2008-02-04',
  '2009': '2009-02-04',
  '2010': '2010-02-04',
  '2011': '2011-02-04',
  '2012': '2012-02-04',
  '2013': '2013-02-04',
  '2014': '2014-02-04',
  '2015': '2015-02-04',
  '2016': '2016-02-04',
  '2017': '2017-02-03',
  '2018': '2018-02-04',
  '2019': '2019-02-04',
  '2020': '2020-02-04',
  '2021': '2021-02-03',
  '2022': '2022-02-04',
  '2023': '2023-02-04',
  '2024': '2024-02-04',
  '2025': '2025-02-03',
  '2026': '2026-02-04',
  '2027': '2027-02-04',
  '2028': '2028-02-04',
  '2029': '2029-02-03',
  '2030': '2030-02-04',
  '2031': '2031-02-04',
  '2032': '2032-02-04',
  '2033': '2033-02-03',
  '2034': '2034-02-04',
  '2035': '2035-02-04',
  '2036': '2036-02-04',
  '2037': '2037-02-03',
  '2038': '2038-02-04',
  '2039': '2039-02-04',
  '2040': '2040-02-04',
  '2041': '2041-02-03',
  '2042': '2042-02-04',
  '2043': '2043-02-04',
  '2044': '2044-02-04',
  '2045': '2045-02-03',
  '2046': '2046-02-04',
  '2047': '2047-02-04',
  '2048': '2048-02-04',
  '2049': '2049-02-03',
  '2050': '2050-02-03',
  '2051': '2051-02-04',
  '2052': '2052-02-04',
  '2053': '2053-02-03',
  '2054': '2054-02-03',
  '2055': '2055-02-04',
  '2056': '2056-02-04',
  '2057': '2057-02-03',
  '2058': '2058-02-03',
  '2059': '2059-02-04',
  '2060': '2060-02-04',
  '2061': '2061-02-03',
  '2062': '2062-02-03',
  '2063': '2063-02-04',
  '2064': '2064-02-04',
  '2065': '2065-02-03',
  '2066': '2066-02-03',
  '2067': '2067-02-04',
  '2068': '2068-02-04',
  '2069': '2069-02-03',
  '2070': '2070-02-03',
  '2071': '2071-02-04',
  '2072': '2072-02-04',
  '2073': '2073-02-03',
  '2074': '2074-02-03',
  '2075': '2075-02-04',
  '2076': '2076-02-04',
  '2077': '2077-02-03',
  '2078': '2078-02-03',
  '2079': '2079-02-04',
  '2080': '2080-02-04',
  '2081': '2081-02-03',
  '2082': '2082-02-03',
  '2083': '2083-02-03',
  '2084': '2084-02-04',
  '2085': '2085-02-03',
  '2086': '2086-02-03',
  '2087': '2087-02-03',
  '2088': '2088-02-04',
  '2089': '2089-02-03',
  '2090': '2090-02-03',
  '2091': '2091-02-03',
  '2092': '2092-02-04',
  '2093': '2093-02-03',
  '2094': '2094-02-03',
  '2095': '2095-02-03',
  '2096': '2096-02-04',
  '2097': '2097-02-03',
  '2098': '2098-02-03',
  '2099': '2099-02-03',
}

export const getLunarYear = (date: Date) => {
  const calendarYear = date.getFullYear().toString()
  const springCommencesDay = new Date(
    // @ts-ignore
    `${SpringCommencesDay[calendarYear]} 00:00:00`,
  )
  if (date < springCommencesDay) {
    return date.getFullYear() - 1
  }
  return date.getFullYear()
}
