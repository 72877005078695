export const general = {
  transparent: 'rgba(0, 0, 0, 0)',
  blackTransparent: 'rgba(0,0,0,0.8)',
  greyTransparent: 'rgba(0,0,0,0.4)',
  whiteTransparent: 'rgba(255 ,255 ,255 ,0.8)',

  black: '#000000',
  white: '#FFFFFF',

  background: '#F1F1F5',

  blacks: ['#000000', '#1D1D1D', '#2A2A2A'],
  greys: ['#333333', '#4F4F4F', '#828282', '#BDBDBD', '#E0E0E0'],

  info: '#2F80ED',
  success: '#27AE60',
  warning: '#FAB123',
  error: '#FF3434',
  disabled: '#E0E0E0',
}
