export const appleSystem = {
  light: {
    grey: '#8E8E93',
    grey2: '#AEAEB2',
    grey3: '#C7C7CC',
    grey4: '#D1D1D6',
    grey5: '#E5E5EA',
    grey6: '#F2F2F7',
    blue: '#007AFF',
    green: '#34C759',
    indigo: '#5856D6',
    orange: '#FF9500',
    pink: '#FF2D55',
    purple: '#AF52DE',
    red: '#FF3B30',
    teal: '#5AC8FA',
    yellow: '#FFCC00',
  },
  dark: {
    grey: '#8E8E93',
    grey2: '#636366',
    grey3: '#48484A',
    grey4: '#3A3A3C',
    grey5: '#2C2C2E',
    grey6: '#1C1C1E',
    blue: '#0A84FF',
    green: '#30D158',
    indigo: '#5E5CE6',
    orange: '#FF9F0A',
    pink: '#FF375F',
    purple: '#BF5AF2',
    red: '#FF453A',
    teal: '#64D2FF',
    yellow: '#FFD60A',
  },
}
