import { memo, useEffect } from 'react'
import { env } from '../utilities'

export const RedirectPage = memo(() => {
  useEffect(() => {
    window.location.href =
      env.redirectUrl ?? `${window.location.host}${env.blessingPagePath}`
  }, [])

  return null
})
