import { memo } from 'react'
import Flatpickr, { DateTimePickerProps } from 'react-flatpickr'
import styled from 'styled-components'

type Props = {
  validationMessage: string | undefined
} & DateTimePickerProps

const Root = styled.div<{ $hasValidationMessage: boolean }>`
  margin-bottom: ${({ theme, $hasValidationMessage }) =>
    theme.spacings.general[$hasValidationMessage ? 1 : 4]}px !important;
  padding-right: 10px;
`

const DatePicker = styled(Flatpickr)<{ $isInvalid: boolean }>`
  background-color: ${({ theme, $isInvalid }) =>
    $isInvalid
      ? theme.palettes.form.invalid
      : theme.palettes.form.normal}!important;
`

const ErrorMessage = styled.p`
  color: ${({ theme }) => theme.palettes.form.errorMessage};
  font-size: ${({ theme }) => theme.fontSizes.small}px;
  padding: ${({ theme }) => theme.spacings.general[1]}px;
  margin: ${({ theme }) => theme.spacings.general[0]}px;
`

export const FormDateInput = memo<Props>((props) => {
  const { validationMessage, ...rest } = props

  return (
    <Root
      $hasValidationMessage={validationMessage != null}
      className="form-group component-input-group input-group theme--medical "
    >
      <i className="icon-calendar" />
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <DatePicker $isInvalid={validationMessage != null} {...rest} />
      {validationMessage && <ErrorMessage>{validationMessage}</ErrorMessage>}
    </Root>
  )
})
