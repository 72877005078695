export const appleCrayon = {
  cantaloupe: 'rgb(255, 206, 110)',
  honeydew: 'rgb(206, 250, 110)',
  spindrift: 'rgb(104, 251, 208)',
  sky: 'rgb(106, 207, 255)',
  lavender: 'rgb(210, 120, 255)',
  carnation: 'rgb(255, 127, 211)',
  licorice: 'rgb(0, 0, 0)',
  snow: 'rgb(255, 255, 255)',
  salmon: 'rgb(255, 114, 110)',
  banana: 'rgb(255, 251, 109)',
  flora: 'rgb(104, 249, 110)',
  ice: 'rgb(104, 253, 255)',
  orchid: 'rgb(110, 118, 255)',
  bubblegum: 'rgb(255, 122, 255)',
  lead: 'rgb(30, 30, 30)',
  mercury: 'rgb(232, 232, 232)',
  tangerine: 'rgb(255, 136, 2)',
  lime: 'rgb(131, 249, 2)',
  seamFoam: 'rgb(3, 249, 135)',
  aqua: 'rgb(0, 140, 255)',
  grape: 'rgb(137, 49, 255)',
  strawberry: 'rgb(255, 41, 135)',
  tungsten: 'rgb(58, 58, 58)',
  silver: 'rgb(208, 208, 208)',
  maraschino: 'rgb(255, 33, 1)',
  lemon: 'rgb(255, 250, 3)',
  spring: 'rgb(5, 248, 2)',
  turquoise: 'rgb(0, 253, 255)',
  blueberry: 'rgb(0, 46, 255)',
  magenta: 'rgb(255, 57, 255)',
  iron: 'rgb(84, 84, 83)',
  magnesium: 'rgb(184, 184, 184)',
  mocha: 'rgb(137, 72, 0)',
  fern: 'rgb(69, 132, 1)',
  moss: 'rgb(1, 132, 72)',
  ocean: 'rgb(0, 74, 136)',
  eggplant: 'rgb(73, 26, 136)',
  maroon: 'rgb(137, 22, 72)',
  steel: 'rgb(110, 110, 110)',
  aluminum: 'rgb(160, 159, 160)',
  cayenne: 'rgb(137, 17, 0)',
  aspargus: 'rgb(136, 133, 1)',
  clover: 'rgb(2, 132, 1)',
  teal: 'rgb(0, 134, 136)',
  midnight: 'rgb(0, 24, 136)',
  plum: 'rgb(137, 30, 136)',
  tin: 'rgb(135, 134, 135)',
  nickel: 'rgb(136, 135, 135)',
}
