import { useQuery } from '@tanstack/react-query'
import { getPaymentResult } from '@ssy-blessing/apis'

export function usePaymentResult(referenceNumber: string | undefined) {
  return useQuery(['result'], () => {
    if (referenceNumber == null || referenceNumber === '') {
      return undefined
    }
    return getPaymentResult(referenceNumber)
  })
}
