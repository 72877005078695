import { ApiResponse } from 'apisauce'

export type ApiProblem =
  | { kind: 'timeout'; temporary: true }
  | { kind: 'cannot-connect'; temporary: true }
  | { kind: 'server' }
  | { kind: 'unauthorized' }
  | { kind: 'forbidden' }
  | { kind: 'not-found' }
  | { kind: 'rejected' }
  | { kind: 'cancelled' }
  | { kind: 'unknown'; temporary: true }
  | { kind: 'unknown' }

/**
 * Attempts to get a common cause of problems from an api response.
 *
 * @param response The api response.
 */
export function getApiProblem(response: ApiResponse<any>): ApiProblem | null {
  if (!response) return { kind: 'cannot-connect', temporary: true }

  switch (response.problem) {
    case 'CONNECTION_ERROR':
      return { kind: 'cannot-connect', temporary: true }
    case 'NETWORK_ERROR':
      return { kind: 'cannot-connect', temporary: true }
    case 'TIMEOUT_ERROR':
      return { kind: 'timeout', temporary: true }
    case 'SERVER_ERROR':
      return { kind: 'server' }
    case 'UNKNOWN_ERROR':
      return { kind: 'unknown', temporary: true }
    case 'CLIENT_ERROR':
      if (response.status === 401) return { kind: 'unauthorized' }
      if (response.status === 403) return { kind: 'forbidden' }
      if (response.status === 404) return { kind: 'not-found' }
      return { kind: 'rejected' }
    case 'CANCEL_ERROR':
      return { kind: 'cancelled' }
    default:
      return { kind: 'unknown' }
  }
}
