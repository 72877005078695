import { memo, useState, useEffect } from 'react'
import styled from 'styled-components'
import { Appbar, Banner, BlessingForm } from '../components'

const Root = styled.div``

export const BlessingPage = memo(() => {
  const [isPopupVisible, setPopupVisible] = useState(false)

  useEffect(() => {
    const handleEsc = (event: { keyCode: number }) => {
      if (event.keyCode === 27) {
        setPopupVisible(false)
      }
    }
    window.addEventListener('keydown', handleEsc)

    return () => {
      window.removeEventListener('keydown', handleEsc)
    }
  }, [])

  return (
    <Root>
      <Appbar />
      <Banner />
      <BlessingForm
        isPopupVisible={isPopupVisible}
        setPopupVisible={setPopupVisible}
      />
    </Root>
  )
})
